import Layout from "@/shared/components/Layout";
import Loader from "@/shared/components/Loader";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

interface GetStaticProps {
  locale: string;
}

const Custom404 = () => {
  const router = useRouter();

  useEffect(() => {
    router.push("/");
  }, [router]);

  return (
    <Layout>
      <div style={{ marginTop: "50px" }}>
        <Loader />
      </div>
    </Layout>
  );
};

export const getStaticProps = async ({ locale }: GetStaticProps) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};

export default Custom404;
